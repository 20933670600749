import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../commonComponents/navigation-menu/navigationService';

@Component({
  selector: 'app-all-brands',
  templateUrl: './all-brands.component.html',
  styleUrls: ['./all-brands.component.css']
})
export class AllBrandsComponent implements OnInit {

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
    this.navigationService.isNavigationMenuDarkMode = true;
  }

}
