import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { AppComponent } from './app.component';
import { HomepageStructureComponent} from './homepage/homepage-structure.component';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsStructureComponent } from './aboutUs/about-us-structure.component';
import { WorksStructureComponent } from './works/works-structure.component';
import { ImageAndCompaniesComponentComponent } from './homepage/components/image-and-companies-component/image-and-companies-component.component';
import { UniqueProjectComponentsComponent } from './homepage/components/unique-project-components/unique-project-components.component';
import { OurMissionComponentsComponent } from './homepage/components/our-mission-components/our-mission-components.component';
import { WorkDesignImageComponentComponent } from './works/components/work-design-image-component/work-design-image-component.component';
import { ProductsStructureComponentComponent } from './products/products-structure-component.component';
import { NavigationMenuComponent } from './commonComponents/navigation-menu/navigation-menu.component';
import { ContactUsComponent } from './homepage/components/contact-us-component/contact-us.component';
import { FooterComponentComponent } from './commonComponents/footer-component/footer-component.component';
import { AllBrandsComponent } from './allBrands/all-brands.component';
import { OurBrandsListComponent } from './allBrands/components/our-brands-list/our-brands-list.component';
import {AllBrandsPipeFilter} from './allBrands/components/AllBrandsPipeFilter';
import { ProductListComponent } from './products/components/product-list/product-list.component';
import {FormsModule} from '@angular/forms';
import { OutletStructureComponentComponent } from './outlet/outlet-structure-component.component';
import { OutletProductListComponent } from './outlet/components/outlet-product-list/outlet-product-list.component';
import { NewsStructureComponentComponent } from './news/news-structure-component.component';
import { NewsContentComponentComponent } from './news/components/news-content-component/news-content-component.component';
import { VitraCollaborationComponentComponent } from './news/components/vitra-collaboration-component/vitra-collaboration-component.component';
import { AboutUsSecondSectionComponent } from './aboutUs/components/about-us-second-section/about-us-second-section.component';
import { AboutUsThirdSectionComponent } from './aboutUs/components/about-us-third-section/about-us-third-section.component';
import { AboutUsFirstSectionComponent } from './aboutUs/components/about-us-first-section/about-us-first-section.component';
import { firebaseConfig } from 'src/environments/environment';

const appRoutes: Routes = [
  { path: '', component: HomepageStructureComponent },
  { path: 'chiSiamo', component: AboutUsStructureComponent },
  { path: 'lavori', component: WorksStructureComponent },
  { path: 'prodotti', component: ProductsStructureComponentComponent },
  { path: 'brands', component: AllBrandsComponent },
  { path: 'outlet', component: OutletStructureComponentComponent },
  { path: 'news', component: NewsStructureComponentComponent },
  { path: 'news/vitra', component: VitraCollaborationComponentComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomepageStructureComponent,
    AboutUsStructureComponent,
    WorksStructureComponent,
    AllBrandsPipeFilter,
    ImageAndCompaniesComponentComponent,
    UniqueProjectComponentsComponent,
    OurMissionComponentsComponent,
    WorkDesignImageComponentComponent,
    ProductsStructureComponentComponent,
    NavigationMenuComponent,
    ContactUsComponent,
    FooterComponentComponent,
    AllBrandsComponent,
    OurBrandsListComponent,
    ProductListComponent,
    OutletStructureComponentComponent,
    OutletProductListComponent,
    NewsStructureComponentComponent,
    NewsContentComponentComponent,
    VitraCollaborationComponentComponent,
    AboutUsSecondSectionComponent,
    AboutUsThirdSectionComponent,
    AboutUsFirstSectionComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    FormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireStorageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
