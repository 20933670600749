<div class="margin-zero-auto w-3/4 py-8 uniqe_project_container">
  <div class="flex items-center lg:flex-wrap">
    <div class="w-1/2 lg:w-full">
      <p class="unique_project_title_text">
        Icona del design
      </p>
      <p class="unique_project_description_text">
        {{firstDescription}}
      </p>
    </div>
    <div class="w-1/2 lg:w-full">
      <img src='./assets/uniqueProjectsImages/bd96c64d29edad0a750794960e0c82fb%201.png'
           alt=""
           class="w-96 margin-zero-auto lg:w-72 lg:py-8"
      />
    </div>
  </div>

  <div class="flex items-center pt-16 lg:flex-wrap lg:pt-4">
    <div class="w-1/2 lg:w-full lg:order-2">
      <img src='./assets/uniqueProjectsImages/Dettagli.jpg'
           alt=""
           class="w-96 margin-zero-auto lg:w-72 lg:py-8"
      />
    </div>
    <div class="w-1/2 lg:w-full lg:order-1">
      <p class="unique_project_title_text">
        Attenzione ai dettagli
      </p>
      <p class="unique_project_description_text">
        {{secondDescription}}
      </p>
    </div>
  </div>

  <div class="flex items-center pt-16 lg:flex-wrap lg:pt-4">
    <div class="w-1/2 lg:w-full">
      <p class="unique_project_title_text">
        Il nostro Showroom
      </p>
      <p class="unique_project_description_text">
        {{thirdDescription}}
      </p>
    </div>
    <div class="w-1/2 lg:w-full">
      <img src='./assets/uniqueProjectsImages/IMG_5435 1.png'
           alt=""
           class="w-96 margin-zero-auto lg:w-72 lg:py-8"
      />
    </div>
  </div>
</div>