<div class="our_mission_main_container">

  <div class="our_mission_content_div_one">
    <h1 class="our_mission_our_mission_text">
      La nostra missione e i nostri servizi, da sempre
    </h1>

    <div class="our_mission_flex_box_container">
      <div *ngFor="let data of missionStructure" class="our_mission_mission_element">
        <h1>
          {{data.missionNumber}}
        </h1>
        <h2>
          {{data.missionTitle}}
        </h2>
        <h3>
          {{data.missionDescription}}
        </h3>
      </div>
    </div>

  </div>


</div>
