import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-view',
  templateUrl: './header-view.component.html',
  styleUrls: ['./header-view.component.css']
})
export class HeaderViewComponent implements OnInit {

  @Input() title: string = '';
  @Input() description?: string = '';
  @Input() textColor: string = 'text-black'; // Default color is black

  constructor() { }

  ngOnInit(): void {
  }

}
