<div class="container mx-auto w-10/12 lg:w-11/12">

  <app-header-view 
  [title]="'Cosa offriamo ai nostri clienti'" 
  [description]="'Scopri la nostra gamma di servizi pensati per soddisfare le tue esigenze, garantendo qualità e affidabilità.'">
  </app-header-view>

  <div class="grid lg:grid-cols-1 grid-cols-2 gap-4">
    <!-- Image Section -->
    <div class="h-auto w-full">
      <img src="../../../../assets/websiteHomepageImage.png" alt="Interior Design" class="w-full h-full object-cover rounded-md" />
    </div>

    <!-- Mission Section -->
    <div class="space-y-2">
      <div 
        *ngFor="let mission of missionStructure; let i = index" 
        class="flex items-start gap-4 bg-gray-50 py-4 px-4 rounded-md hover:bg-red-500 hover:text-white transition-colors duration-300"
      >
        <!-- Mission Number -->
        <div 
          class="w-16 h-16 flex items-center justify-center font-bold text-lg">
          {{ mission.missionNumber }}
        </div>

        <!-- Mission Content -->
        <div class="flex-1">
          <h3 
            class="leading-tight font-semibold text-xl"
          >
            {{ mission.missionTitle }}
          </h3>
          <p 
            class="text-sm mt-2 leading-loose"
          >
            {{ mission.missionDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
