import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../commonComponents/navigation-menu/navigationService';

@Component({
  selector: 'app-about-us-structure',
  templateUrl: './about-us-structure.component.html',
  styleUrls: ['./about-us-structure.component.css']
})
export class AboutUsStructureComponent implements OnInit {

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
    this.navigationService.isNavigationMenuDarkMode = true;
  }

}
