<div>

  <form>
    <input
      placeholder="Cerca per nome..."
      type="text"
      name="fname"
      id="search-text"
      aria-describedby="search-text"
      [(ngModel)]="searchText"
      autofocus>
    <br>
  </form>

  <hr class="solid">

  <div class="flex justify-between flex-wrap">
    <div *ngFor="let data of companiesStructure | clientAppFilter: searchText" class="w-1/4 py-8 lg:w-1/2">
      <img
      src={{data.imageName}}
      class="w-7/12 margin-zero-auto"
      alt={{data.imageAlt}}
    />
    </div>
  </div>

</div>