import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../commonComponents/navigation-menu/navigationService';

@Component({
  selector: 'app-image-and-companies-component',
  templateUrl: './image-and-companies-component.component.html',
  styleUrls: ['./image-and-companies-component.component.css']
})
export class ImageAndCompaniesComponentComponent implements OnInit {

  companiesStructure = [
    { id: 1, imageName: './assets/companiesLogo/Vitra.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 2, imageName: './assets/companiesLogo/Poltrona_Frau.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 3, imageName: './assets/companiesLogo/molteni.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 4, imageName: './assets/companiesLogo/Logo.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 5, imageName: './assets/companiesLogo/seletti.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 6, imageName: './assets/companiesLogo/mesons.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 7, imageName: './assets/companiesLogo/flou.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 8, imageName: './assets/companiesLogo/edonè.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 9, imageName: './assets/companiesLogo/london art.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 10, imageName: './assets/companiesLogo/cattelani.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 11, imageName: './assets/companiesLogo/zanotta.png', imageAlt: 'Poltrona Frau Official Image' },
    { id: 12, imageName: './assets/companiesLogo/rimadesio.png', imageAlt: 'Poltrona Frau Official Image' },
  ];

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  setNavigationServiceProps(dark: boolean): void {
    console.log('Setting mode');
    this.navigationService.isNavigationMenuDarkMode = dark;
  }

  openNewsletter() {
    window.open('https://tinyletter.com/IconaArredamenti', 'popupwindow', 'scrollbars=yes,width=800,height=600'); return true
  }
}