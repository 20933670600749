<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="w-10/12 mx-auto">

    <app-header-view 
      [title]="'Chi siamo?'">
    </app-header-view>

    <app-about-us-first-section></app-about-us-first-section>

    <app-about-us-second-section></app-about-us-second-section>

    <app-about-us-third-section></app-about-us-third-section>
  </div>

  <app-footer-component></app-footer-component>
</div>