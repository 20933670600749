import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../commonComponents/navigation-menu/navigationService';

@Component({
  selector: 'app-works-structure',
  templateUrl: './works-structure.component.html',
  styleUrls: ['./works-structure.component.css']
})
export class WorksStructureComponent implements OnInit {

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
    this.navigationService.isNavigationMenuDarkMode = true;
  }

}
