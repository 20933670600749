import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us-component',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onEmailClick(): void {
    window.location.href = `mailto:${'info@iconarredamenti.it?subject=Icona arredamenti contattaci!'}`;
  }
}
