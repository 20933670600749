<div>
  <h1 class="contact_us_section_title">
    Contattaci
  </h1>

  <div class="contact_us_possibility_container">
    <div>
      <h1 class="contact_us_title_text">
        TELEFONO
      </h1>
      <a class="text-decoration-none contact_us_title_description" href="tel:0444560138">
        0444.560138
      </a>
    </div>
    <div>
      <h1 class="contact_us_title_text">
        INDIRIZZO
      </h1>
      <a class="text-decoration-none"
        href="https://www.google.com/maps/search/?api=1&query=45.54508489047022,11.523196640514943">
        <h2 class="contact_us_title_description">
          Viale Mercato Nuovo, 38
        </h2>
      </a>
    </div>
    <div class="padding-top">
      <h1 class="contact_us_title_text">
        EMAIL
      </h1>
      <h2 class="contact_us_email" (click)="onEmailClick()">
        info@iconarredamenti.it
      </h2>
    </div>
    <div>
      <h1 class="contact_us_title_text">
        ORARI
      </h1>
      <h2 class="contact_us_title_description">
        Mar-Sab: 
      </h2>
      <h2 class="contact_us_title_description">
        9.00-12:30 / 15:00-19:30
      </h2>
      <h2 class="contact_us_title_description">
        Lun: 
      </h2>
      <h2 class="contact_us_title_description">
        Lun: 15.00-19:30
      </h2>
    </div>
  </div>
  <!--
  <div class="contact_us_container">
    <form class="contact_us_form_content">
      <input type="text" id="fname" name="fname" placeholder="Nome e cognome"><br>
      <input type="text" id="lname" name="lname" placeholder="Email">
    </form>

    <form class="contact_us_form_content_text_area">
      <textarea placeholder="Scrivi il tuo messaggio qui..."></textarea>
    </form>
  </div>
  -->
</div>