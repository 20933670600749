<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="brands_component_main_structure">

    <div class="brands_component_title_container">
      <h1 class="brands_main_title_our_products">
        I nostri brand
      </h1>
    </div>

    <hr class="solid">

    <app-our-brands-list></app-our-brands-list>

  </div>

  <app-footer-component></app-footer-component>
</div>
