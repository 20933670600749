<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="outlet_component_main_structure">

    <div class="outlet_component_title_container">
      <h1 class="outlet_main_title_our_products">
        Outlet
      </h1>
    </div>

    <hr class="solid">

    <app-outlet-product-list></app-outlet-product-list>
  </div>

  <app-footer-component></app-footer-component>
</div>
