<div class="container mx-auto w-10/12">
    <!-- Title Section -->
    <app-header-view 
      [title]="'I Nostri Brand di fiducia'" 
      [description]="'Collaboriamo con i migliori brand del settore per offrirti soluzioni di arredamento uniche, caratterizzate da qualità, stile e innovazione. Ogni partner è scelto per garantirti il massimo in ogni dettaglio.'">
    </app-header-view>
  
    <!-- Brands Section -->
    <div class="grid gap-6 lg:grid-cols-2 xl:grid-cols-6">
      <!-- Company Images Loop -->
      <div *ngFor="let data of companiesStructure" class="flex items-center justify-center">
        <img src="{{data.imageName}}" alt="{{data.imageAlt}}" class="w-24 h-24 lg:w-32 lg:h-32 xl:w-48 xl:h-48 object-contain" />
      </div>
    </div>
  
    <!-- Show More Button -->
    <div class="flex justify-center pt-16">
      <button routerLink="/brands" (click)="setNavigationServiceProps(true)"
        class="bg-white text-red-500 px-4 lg:px-6 py-2 lg:py-3 border-2 border-red-500 rounded-md hover:bg-red-500 hover:text-white transition transform hover:scale-105">
        <h1 class="text-base lg:text-lg font-light">SCOPRILI TUTTI</h1>
      </button>
    </div>
</div>
