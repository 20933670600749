<div 
  [ngClass]="retrieveTopColor() + (isMobileMenuOpen ? ' border-b-0' : ' border-b-2')"
>
  <nav 
    class="w-10/12 mx-auto border-2 border-b-0 border-t-0 lg:w-full"
    [ngClass]="retrieveBorderColor()"
  >
    <div class="w-11/12 mx-auto lg:w-10/12">
      <div class="flex justify-between items-center py-8 h-[15vh]">
        <div>
          <a [routerLink]="['/']" class="focus:outline-none">
            <img src="../../../assets/iconaArredamentiLogo.png" alt="" class="w-32" />
          </a>
        </div>

        <div 
          class="flex dark:text-white text-lg lg:hidden items-center"
        >
          <a 
            routerLink="/chiSiamo"
            class="ml-12 hover:underline text-black"
          >
            CHI SIAMO
          </a>
          <a 
            routerLink="/lavori"
            class="ml-12 hover:underline text-black"
          >
            LAVORI
          </a>
          <a 
            routerLink="/news"
            class="ml-12 hover:underline text-black"
          >
            NEWS
          </a>
          <a 
            routerLink="/outlet"
            class="ml-12 hover:underline text-black"
          >
            OUTLET
          </a>
          <a 
            routerLink="/prodotti"
            class="ml-12 hover:underline text-black"
          >
            PRODOTTI
          </a>
        </div>
        <button 
          class="hidden lg:block" 
          (click)="setMobileMenuStatus()"
        >
          <img 
            src="./assets/hamburgerMenuDark.svg" 
            class="w-12" 
            alt="Hamburger Menu"
          />
        </button>
      </div>
    </div>
  </nav>

  <!-- Full-height centered mobile menu -->
  <div 
    *ngIf="isMobileMenuOpen"
    class="fixed inset-0 bg-white flex justify-center items-center z-50"
  >
    <div class="flex flex-col items-center text-center space-y-4">
      <a 
        class="py-4 text-2xl text-black border-b border-gray-300 w-full text-center"
        routerLink="/chiSiamo" 
        (click)="setMobileMenuStatus()"
      >
        chi siamo
      </a>
      <a 
        class="py-4 text-2xl text-black border-b border-gray-300 w-full text-center"
        routerLink="/news" 
        (click)="setMobileMenuStatus()"
      >
        news
      </a>
      <a 
        class="py-4 text-2xl text-black border-b border-gray-300 w-full text-center"
        routerLink="/lavori" 
        (click)="setMobileMenuStatus()"
      >
        lavori
      </a>
      <a 
        class="py-4 text-2xl text-black border-b border-gray-300 w-full text-center"
        routerLink="/outlet" 
        (click)="setMobileMenuStatus()"
      >
        outlet
      </a>
      <a 
        class="py-4 text-2xl text-black border-b border-gray-300 w-full text-center"
        routerLink="/brands" 
        (click)="setMobileMenuStatus()"
      >
        i nostri brands
      </a>
      <a 
        class="py-4 text-2xl text-black w-full text-center"
        routerLink="/prodotti" 
        (click)="setMobileMenuStatus()"
      >
        prodotti
      </a>
    </div>
  </div>
</div>
