<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="about_us_component_main_structure">

    <div class="about_us_component_title_container">
      <h1 class="about_us_title_about_us">
        Chi siamo?
      </h1>
    </div>

    <hr class="solid">

    <app-about-us-first-section></app-about-us-first-section>
    <hr class="solid">
    <app-about-us-second-section></app-about-us-second-section>
    <hr class="solid">
    <app-about-us-third-section></app-about-us-third-section>
  </div>

  <app-footer-component></app-footer-component>
</div>