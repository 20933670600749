<div class="margin-zero-auto py-8 uniqe_project_container">
    <div class="flex items-center lg:flex-wrap">
      <div class="w-1/2 lg:w-full">
        <p class="description-text">
            Icona Arredamenti è un importante punto di riferimento per l’interior design a Vicenza, nato dalla collaborazione tra Lorenzo Brazzale e Roberto Candia, che con il loro staf accompagnano il cliente in un originale percorso che trasforma la casa in un luogo unico e personale. Per questo motivo sono diventati l'immagine di un nuovo modo di concepire il negozio di arredamento, dove l’accurato studio degli spazi precede sempre la scelta dei mobili e dove il design è valorizzato dalla progettazione degli ambienti, vestendo la casa con eleganza e raffinatezza.
            Il gusto contemporaneo viene supportato da affermate aziende di primo piano a livello internazionale, scelte scrupolosamente per offrire il miglior rapporto qualità-prezzo. Il servizio viene affidato a personale qualificato tra architetti e designer, con un bagaglio di conoscenze e competenze in grado di soddisfare il cliente fornendo una vasta e completa gamma di servizi.
            Il negozio si avvale anche di ottimi collaboratori per il montaggio degli arredi. Persone affidabili, capaci e disponibili, sempre pronti ad assecondare le richieste di arredatori, puntali e precisi negli interventi, che garantiscono una scrupolosa attenzione ai dettagli, dovuta a una lunga esperienza sui materiali, pelli, tessuti, marmi e legni.
        </p>
      </div>
      <div class="w-1/2 lg:w-full">
        <img src="./assets/_DSC5733%201.png" class="w-96 margin-zero-auto lg:w-3/4 lg:py-8" alt="Team image" />
      </div>
    </div>
</div>