import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-our-brands-list',
  templateUrl: './our-brands-list.component.html',
  styleUrls: ['./our-brands-list.component.css']
})
export class OurBrandsListComponent implements OnInit {

  searchText = '';

  companiesStructure = [
    {
      id: 1,
      imageName: './assets/companiesLogo/cattelani.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Cattelani'
    },
    {
      id: 2,
      imageName: './assets/companiesLogo/ditre.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Ditre'
    },
    {
      id: 3,
      imageName: './assets/companiesLogo/edonè.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Edonè'
    },
    {
      id: 4,
      imageName: './assets/companiesLogo/flou.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Flou'
    },
    {
      id: 5,
      imageName: './assets/companiesLogo/Logo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Dada'
    },
    {
      id: 6,
      imageName: './assets/companiesLogo/london art.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'London art'
    },
    {
      id: 7,
      imageName: './assets/companiesLogo/mesons.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Mesons'
    },
    {
      id: 8,
      imageName: './assets/companiesLogo/Nemo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Nemo'
    },
    {
      id: 9,
      imageName: './assets/companiesLogo/Poltrona_Frau.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Poltrona Frau'
    },
    {
      id: 10,
      imageName: './assets/companiesLogo/riflessi.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Riflessi'
    },
    {
      id: 11,
      imageName: './assets/companiesLogo/molteni.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Molteni',
    },
    {
      id: 12,
      imageName: './assets/companiesLogo/rimadesio.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Rimadesio'
    },
    {
      id: 13,
      imageName: './assets/companiesLogo/seletti.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Seletti'
    },
    {
      id: 14,
      imageName: './assets/companiesLogo/Vitra.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Vitra'
    },
    {
      id: 15,
      imageName: './assets/companiesLogo/zanotta.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Zanotta'
    },
    {
      id: 16,
      imageName: './assets/companiesLogo/Artek.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Artek'
    },
    {
      id: 17,
      imageName: './assets/companiesLogo/bontempi.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Bontempi'
    },
    {
      id: 18,
      imageName: './assets/companiesLogo/clei-logo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Clei'
    },
    {
      id: 19,
      imageName: './assets/companiesLogo/coro-logo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Coro'
    },
    {
      id: 20,
      imageName: './assets/companiesLogo/EMU.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'EMU'
    },
    {
      id: 21,
      imageName: './assets/companiesLogo/Falomo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Falomo'
    },
    {
      id: 22,
      imageName: './assets/companiesLogo/gaggenau.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Gaggenau'
    },
    {
      id: 23,
      imageName: './assets/companiesLogo/KRISTALIA.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'KRISTALIA'
    },
    {
      id: 24,
      imageName: './assets/companiesLogo/lapalma.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'lapalma'
    },
    {
      id: 25,
      imageName: './assets/companiesLogo/logoneff.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'logoneff'
    },
    {
      id: 26,
      imageName: './assets/companiesLogo/Louis-Poulsen.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Louis-Poulsen'
    },
    {
      id: 27,
      imageName: './assets/companiesLogo/natevo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'natevo'
    },
    {
      id: 28,
      imageName: './assets/companiesLogo/roda.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'roda'
    },
    {
      id: 29,
      imageName: './assets/companiesLogo/Ronda-Design-logo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Ronda'
    },
    {
      id: 30,
      imageName: './assets/companiesLogo/sangiacomo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'sangiacomo'
    },
    {
      id: 31,
      imageName: './assets/companiesLogo/SLAMP-LOGO.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'SLAMP'
    },
    {
      id: 33,
      imageName: './assets/companiesLogo/Varaschin-logo.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Varaschin'
    },
    {
      id: 34,
      imageName: './assets/companiesLogo/Amini.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Amini'
    },
    {
      id: 35,
      imageName: './assets/companiesLogo/arper.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'arper'
    },
    {
      id: 36,
      imageName: './assets/companiesLogo/Capo d_Opera.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Capo d opera'
    },
    {
      id: 37,
      imageName: './assets/companiesLogo/Fiam.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Fiam'
    },
    {
      id: 38,
      imageName: './assets/companiesLogo/fontana.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'fontana'
    },
    {
      id: 39,
      imageName: './assets/companiesLogo/kasthall.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'kasthall'
    },
    {
      id: 40,
      imageName: './assets/companiesLogo/livingdivani.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'livingdivani'
    },
    {
      id: 41,
      imageName: './assets/companiesLogo/Miele.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Miele'
    },
    {
      id: 42,
      imageName: './assets/companiesLogo/MOVI.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'MOVI'
    },
    {
      id: 43,
      imageName: './assets/companiesLogo/Oluce.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Oluce'
    },
    {
      id: 44,
      imageName: './assets/companiesLogo/Penta.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Penta'
    },
    {
      id: 45,
      imageName: './assets/companiesLogo/Porada.png',
      imageAlt: 'Poltrona Frau Official Image',
      brandName: 'Porada'
    },
    {
      id: 46,
      imageName: './assets/companiesLogo/Archiutti.png',
      imageAlt: 'Archiutti Official Image',
      brandName: 'Archiutti'
    },
    {
      id: 47,
      imageName: './assets/companiesLogo/Artemide.png',
      imageAlt: 'Artemide Official Image',
      brandName: 'Artemide'
    },
    {
      id: 48,
      imageName: './assets/companiesLogo/Astep.png',
      imageAlt: 'Astep Official Image',
      brandName: 'Astep'
    },
    {
      id: 49,
      imageName: './assets/companiesLogo/Bosa.png',
      imageAlt: 'Bosa Official Image',
      brandName: 'Bosa'
    },
    {
      id: 50,
      imageName: './assets/companiesLogo/Dcw.png',
      imageAlt: 'DCW Official Image',
      brandName: 'DCW'
    },
    {
      id: 51,
      imageName: './assets/companiesLogo/Dibiesse.png',
      imageAlt: 'Dibiesse Official Image',
      brandName: 'Dibiesse'
    },
    {
      id: 52,
      imageName: './assets/companiesLogo/Diemme.png',
      imageAlt: 'Diemme Official Image',
      brandName: 'Diemme'
    },
    {
      id: 53,
      imageName: './assets/companiesLogo/Flos.png',
      imageAlt: 'Flos Official Image',
      brandName: 'Flos'
    },
    {
      id: 54,
      imageName: './assets/companiesLogo/fritz_hansen.png',
      imageAlt: 'Fritz Hansen Official Image',
      brandName: 'Fritz Hansen'
    },
    {
      id: 55,
      imageName: './assets/companiesLogo/GallottiRadice.png',
      imageAlt: 'Gallotti&Radice Official Image',
      brandName: 'Gallotti&Radice'
    },
    {
      id: 56,
      imageName: './assets/companiesLogo/Gufram.png',
      imageAlt: 'Gufram Official Image',
      brandName: 'Gufram'
    },
    {
      id: 57,
      imageName: './assets/companiesLogo/Kettal.png',
      imageAlt: 'Kettal Official Image',
      brandName: 'Kettal'
    },
    {
      id: 58,
      imageName: './assets/companiesLogo/Lodes.png',
      imageAlt: 'Lodes Official Image',
      brandName: 'Lodes'
    },
    {
      id: 59,
      imageName: './assets/companiesLogo/Matteo Pala.png',
      imageAlt: 'Matteo Pala Official Image',
      brandName: 'Matteo Pala'
    },
    {
      id: 60,
      imageName: './assets/companiesLogo/Panzeri.png',
      imageAlt: 'Panzeri Official Image',
      brandName: 'Panzeri'
    },
    {
      id: 61,
      imageName: './assets/companiesLogo/Qeeboo.png',
      imageAlt: 'Qeeboo Official Image',
      brandName: 'Qeeboo'
    },
    {
      id: 62,
      imageName: './assets/companiesLogo/Serax.png',
      imageAlt: 'Serax Official Image',
      brandName: 'Serax'
    },
    {
      id: 63,
      imageName: './assets/companiesLogo/Talenti.png',
      imageAlt: 'Talenti Official Image',
      brandName: 'Talenti'
    },
    {
      id: 64,
      imageName: './assets/companiesLogo/Toulemonde Bochart.png',
      imageAlt: 'Toulemonde Bochart Official Image',
      brandName: 'Toulemonde Bochart'
    },
    {
      id: 65,
      imageName: './assets/companiesLogo/Zava.png',
      imageAlt: 'Zava Official Image',
      brandName: 'Zava'
    },
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}