<div class="pb-8">
    <p class="text-4xl font-light py-8">
        Il nostri Eventi
    </p>

    <p class="description-text pb-8">
        <span class="font-medium text-xl">
            Storie di design a Vicenza:
        </span> <br>
        quando il design unisce una cittá.
Nella splendida cornice di Piazza dei Signori abbiamo organizzato "STORIE DI DESIGN A VICENZA", una settimana dedicata alla cultura del design.
Dal 12 al 18 novembre 2018 la nostra splendida città è diventata centro del design con mostre, conferenze e una rassegna sui grandi maestri del '900 italiano come Vico Magistretti e Gio Ponti per arrivare fino ai giorni nostri rappresentati da nomi internazionali come Palomba Serafini Associati e Vincent Van Duysen.
Protagonisti della serie di eventi sono state aziende leader del settore come Poltrona Frau, Vitra , Molteni, Dada , Flou che hanno allestito dei veri e propri atelier in Basilica Palladiana, Palazzo Cordellina e Loggia del Capitaniato. Non potevano essere scelte location migliori per condurre lo spettatore ad una approfondita conoscenza dell’arte e del design e dei sui rappresentanti migliori. Un’atmosfera di poesia, dove il palcoscenico era parte integrante della magia. Un percorso che ha tenuto alta l’attenzione su un argomento così importante come il design, imprescindibile per chi come noi, vuole arredare con ricercatezza e mai volgare.
    </p>  

    <div class="aspect-w-16 aspect-h-7 w-3/4 lg:w-full margin-zero-auto">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/ElsM4xZ7MGc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
</div>