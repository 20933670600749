import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/commonComponents/navigation-menu/navigationService';
import { ActivatedRoute } from '@angular/router';
import { NewsDetail } from '../../news-structure-component.component';

@Component({
  selector: 'app-vitra-collaboration-component',
  templateUrl: './vitra-collaboration-component.component.html',
  styleUrls: ['./vitra-collaboration-component.component.css']
})
export class VitraCollaborationComponentComponent implements OnInit {

  constructor(
    public navigationService: NavigationService,
    private route: ActivatedRoute
  ) { }

  data: NewsDetail = {} as NewsDetail;

  imageList: string[];

  middleImage: string;

  ngOnInit(): void {
    this.navigationService.isNavigationMenuDarkMode = true;
    this.route.queryParams.subscribe(params => {
      const dataString = decodeURI(params['data']);
      this.imageList = params['urls']
      this.middleImage = params['middleImage']
      this.data = JSON.parse(dataString) as NewsDetail;
    })
  }
}
