<div [ngClass]="{homepage_container: navigationService.isHamburgerMenuOpen}">

  <app-image-and-companies-component></app-image-and-companies-component>

  <app-unique-project-components></app-unique-project-components>

  <app-our-mission-components></app-our-mission-components>

  <app-contact-us-component></app-contact-us-component>

  <app-footer-component></app-footer-component>

</div>