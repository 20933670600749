<div class="w-3/4 margin-zero-auto lg:w-11/12 pb-8"
    [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
    <div class="flex items-center py-8">
        <h1 class="title-text">
            {{data.title}}
        </h1>
    </div>
    <hr class="solid">
    <p class="description-text text-center pt-8">
        {{data.subtitle}}
    </p>
    <img [src]="middleImage" class="w-full margin-zero-auto py-8"
        alt="Collaborazion Vitra Icona Arredamenti immagine" />
    <div class="w-full">
        <p class="description-text">
            {{data.description}}
        </p>

        <div [innerHtml]="data.customView"></div>

        <div *ngFor="let image of imageList">
            <img [src]="image" class="w-full pt-8">
        </div>
    </div>
</div>
<app-footer-component></app-footer-component>