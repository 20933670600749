<div class="pb-8">
  <!-- Filter Container -->
  <div class="flex flex-wrap justify-center gap-12 pt-8 pb-8">
    <div *ngFor="let data of worksFilter">
      <button 
        class="outline-none cursor-pointer bg-transparent border-none" 
        (click)="changeCurrentFilterSelection(data)"
      >
        <h1 
          class="font-light text-xl hover:underline text-center"
          [ngClass]="{'underline font-semibold': currentSelectedFilter == data}"
        >
          {{data}}
        </h1>
      </button>
    </div>
  </div>

  <hr class="border-t border-gray-100"/>

  <!-- Design Element Container -->
  <div class="grid lg:grid-cols-1 grid-cols-2 gap-8 pt-16 w-full mx-auto">
    <div 
      *ngFor="let data of getCurrentArray()" 
      class="flex flex-col items-center"
    >
      <img 
        [src]="data.workImage" 
        class="object-contain w-full h-auto rounded-md" 
        alt="" 
      />
      <h1 class="text-3xl font-light mt-4 mb-8 text-center">
        {{data.workTitle}}
      </h1>
    </div>
  </div>
</div>
