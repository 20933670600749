<div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 py-8">
  <div 
    *ngFor="let data of productStructure" 
    class="flex flex-col items-center pb-8"
  >
    <!-- Product Image -->
    <div class="w-full h-[200px] lg:h-[250px] overflow-hidden rounded-md">
      <img
        [src]="data.imageName"
        class="w-full h-full object-cover"
        [alt]="data.imageAlt"
      />
    </div>

    <!-- Product Details -->
    <h1 class="text-lg font-medium mt-4 text-center">
      {{data.productName}}
    </h1>
    <h1 *ngIf="data.prezzoDiListino" class="text-sm font-light mt-2 text-center">
      Prezzo di listino: <span class="font-semibold">{{data.prezzoDiListino}}</span>
    </h1>
    <h1 class="text-sm font-light mt-2 text-center">
      Prezzo: <span class="font-semibold">{{data.realPrice}}</span>
    </h1>
  </div>
</div>
