<div class="pb-8">
    <p class="text-4xl font-light py-8">
        Il nostro Showroom
    </p>

    <p class="description-text">
        Il nostro Showroom, ideato in collaborazione con Molteni & Co, si sviluppa su due piani ed accoglie calorosamente tutti coloro che entrano, offrendo un’ampia selezione di ambienti, dalle cucine alle aree living fino agli spazi notte. Il negozio è distribuito su un’importante superficie espositiva di 2000 mtq con relativo servizio di progettazione e realizzazione di arredi su misura sia per abitazioni che per locali commerciali, uffici e negozi. Il tutto combinato con armonia, come un puzzle ogni pezzo si integra al successivo dando origine a colori, texture e ambienti fluidi e piacevoli.
    </p>      


        <div id="carouselExampleIndicators" class="carousel slide relative lg:w-full w-3/4 margin-zero-auto pt-8" data-bs-ride="carousel">
            <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
          <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
        <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="6"
        aria-label="Slide 7"
      ></button>
      <button
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide-to="7"
      aria-label="Slide 8"
    ></button>
    <button
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide-to="8"
    aria-label="Slide 9"
  ></button>
  <button
  type="button"
  data-bs-target="#carouselExampleIndicators"
  data-bs-slide-to="9"
  aria-label="Slide 10"
></button>
            </div>
            <div class="carousel-inner relative w-full overflow-hidden">
              <div class="carousel-item active float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom1.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom2.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom3.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom4.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom5.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom6.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom7.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom8.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom9.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
              <div class="carousel-item float-left w-full">
                <img
                  src="./assets/about-us-showroom/showroom10.png"
                  class="block w-full"
                  alt="Showroom image"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
</div>