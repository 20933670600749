import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-mission-components',
  templateUrl: './our-mission-components.component.html',
  styleUrls: ['./our-mission-components.component.css']
})
export class OurMissionComponentsComponent implements OnInit {

  missionStructure = [
    {
      id: 1,
      missionNumber: '01',
      missionTitle: 'PROGETTAZIONE E VENDITA',
      missionDescription: 'Rilievo misure a domicilio. Il servizio comprende il coordinamento dei lavori in cantiere oltre alla possibilità di eseguire modifiche e lavorazioni su misura. Consulenza presso i cantieri per impiantistica elettrica ed idraulica. Consulenza per rifiniture architettoniche (cartongessi, pitture, materiali, pavimenti e rivestimenti) e di completamento all\'arredo (luci, tendaggi ecc.). Progettazione e arredo di abitazioni private, casa vacanze, uffici, attività commerciali, hotel e residence.'
    },
    {
      id: 2,
      missionNumber: '02',
      missionTitle: 'TRASPORTO E MONTAGGIO',
      missionDescription: 'Il montaggio rappresenta uno step molto importante per icona arredamenti, infatti per dare un servizio all\'altezza la ditta si affida a propri professionisti con esperienza pluriennale nel settore. Si differenziano dal resto per la loro precisione, il lavoro meticoloso e la grande capacità nell\'affrontare qualsiasi tipo di montaggio.'
    },
    {
      id: 3,
      missionNumber: '03',
      missionTitle: 'ASSISTENZA POST-VENDITA',
      missionDescription: 'Il vostro investimento avrà un futuro negli anni grazie alla nostra assistenza. Lo Staff di icona ti supporterà nella post-vendita.'
    },
    {
      id: 4,
      missionNumber: '04',
      missionTitle: 'FALEGNAMERIA TAYLOR MADE',
      missionDescription: 'Il nostro personale vanta una grande esperienza nel montaggio di mobili di serie e su misura, eseguono lavori di falegnameria di alto livello per soddisfare tutte le esigenze al centimetro.'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
