<div class="mx-auto">
  <div class="flex items-center lg:flex-wrap xl:flex-nowrap">
      <!-- Description Section -->
      <div class="w-1/2 xl:w-1/2 lg:w-full lg:mb-8">
          <p class="description-text text-justify px-4 xl:px-0">
              Icona Arredamenti è un importante punto di riferimento per l’interior design a Vicenza, nato dalla collaborazione tra Lorenzo Brazzale e Roberto Candia, che con il loro staf accompagnano il cliente in un originale percorso che trasforma la casa in un luogo unico e personale. Per questo motivo sono diventati l'immagine di un nuovo modo di concepire il negozio di arredamento, dove l’accurato studio degli spazi precede sempre la scelta dei mobili e dove il design è valorizzato dalla progettazione degli ambienti, vestendo la casa con eleganza e raffinatezza.
              Il gusto contemporaneo viene supportato da affermate aziende di primo piano a livello internazionale, scelte scrupolosamente per offrire il miglior rapporto qualità-prezzo. Il servizio viene affidato a personale qualificato tra architetti e designer, con un bagaglio di conoscenze e competenze in grado di soddisfare il cliente fornendo una vasta e completa gamma di servizi.
              Il negozio si avvale anche di ottimi collaboratori per il montaggio degli arredi. Persone affidabili, capaci e disponibili, sempre pronti ad assecondare le richieste di arredatori, puntali e precisi negli interventi, che garantiscono una scrupolosa attenzione ai dettagli, dovuta a una lunga esperienza sui materiali, pelli, tessuti, marmi e legni.
          </p>
      </div>
      <!-- Image Section -->
      <div class="w-1/2 xl:w-1/2 lg:w-full flex justify-center">
          <img 
              src="./assets/team_image.png" 
              class="w-96 lg:w-3/4 xl:w-4/5 max-w-full mx-auto rounded-md"
              alt="Team image"/>
      </div>
  </div>
</div>
