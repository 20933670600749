<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="w-10/12 lg:w-11/12 margin-zero-auto">

    <header>
      <app-header-view [title]="'Lavori'"></app-header-view>
    </header>

    <hr class="border-t border-gray-100"/>

    <app-work-design-image-component></app-work-design-image-component>

  </div>

  <app-footer-component></app-footer-component>

</div>
