<div class="custom-background-color border-0" [class]='retrieveTopColor()'
  [ngClass]="isMobileMenuOpen ? 'border-b-0' : 'border-b-2'">
  <nav class="w-3/4 margin-zero-auto border-2 border-b-0 border-t-0 lg:w-full" [class]='retrieveBorderColor()'>
    <div class="w-11/12 margin-zero-auto lg:w-10/12">
      <div class="flex justify-between items-center py-8 h-15v">
        <div>
          <a [routerLink]="['/']" class="focus:outline-none">
            <img src="../../../assets/iconaArredamentiLogo.png" alt="" class="w-32">
          </a>
        </div>

        <div class="flex dark:text-white text-black text-lg lg:hidden items-center">
          <a [class]="navigationService.isNavigationMenuDarkMode ? 'navigation_menu_link_text_black' : 'navigation_menu_link_text_black'"
            routerLink="/chiSiamo">
            CHI SIAMO
          </a>
          <a [class]="navigationService.isNavigationMenuDarkMode ? 'navigation_menu_link_text_black' : 'navigation_menu_link_text_black'"
            routerLink="/lavori">
            LAVORI
          </a>
          <a [class]="navigationService.isNavigationMenuDarkMode ? 'navigation_menu_link_text_black' : 'navigation_menu_link_text_black'"
          routerLink="/news">
          NEWS
        </a>

          <a [class]="navigationService.isNavigationMenuDarkMode ? 'navigation_menu_link_text_black' : 'navigation_menu_link_text_black'"
            routerLink="/outlet">
            OUTLET
          </a>

          <a [class]="navigationService.isNavigationMenuDarkMode ? 'navigation_menu_link_text_black' : 'navigation_menu_link_text_black'"
            routerLink="/prodotti">
            PRODOTTI
          </a>
        </div>
        <button class="hide-hamburger-menu" (click)="setMobileMenuStatus()">
          <img src="./assets/hamburgerMenuDark.svg" class="navigation_menu_hamburger_menu_image" alt="" />
        </button>
      </div>
    </div>
  </nav>
  <div class="h-75v white-background-color text-black flex justify-center flex-col text-center text-2xl"
    *ngIf="isMobileMenuOpen">

    <a class="py-4 border-b-2 border-black-color" routerLink="/chiSiamo" (click)="setMobileMenuStatus()">
      chi siamo
    </a>

    <a class="py-4 border-b-2 border-black-color" routerLink="/news" (click)="setMobileMenuStatus()">
      news
    </a>

    <a class="py-4 border-b-2 border-black-color" routerLink="/lavori" (click)="setMobileMenuStatus()">
      lavori
    </a>

    <a class="py-4 border-b-2 border-black-color" routerLink="/outlet" (click)="setMobileMenuStatus()">
      outlet
    </a>

    <a class="py-4 border-b-2 border-black-color" routerLink="/brands" (click)="setMobileMenuStatus()">
      i nostri brands
    </a>

    <a class="py-4" routerLink="/prodotti" (click)="setMobileMenuStatus()">
      prodotti
    </a>

  </div>
</div>