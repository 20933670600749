<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="works_component_main_structure">

    <div class="works_component_title_container">
      <h1 class="works_main_title_elegance">
        Lavori
      </h1>
    </div>

    <hr class="solid">

    <app-work-design-image-component></app-work-design-image-component>

  </div>

  <app-footer-component></app-footer-component>

</div>
