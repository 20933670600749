<div class="w-full max-w-screen-lg mx-auto p-4 lg:px-8">
    <!-- Cover Image -->
    <div class="w-full h-[250px] lg:h-[300px] overflow-hidden rounded-md">
      <img 
        [src]="coverImage" 
        class="w-full h-full object-cover" 
        [alt]="data.altText" 
      />
    </div>
  
    <!-- Title -->
    <p class="text-left font-semibold pt-4 text-lg">
      {{data.title}}
    </p>
  
    <!-- Date -->
    <p class="text-left text-sm font-light text-gray-600">
      {{data.date}}
    </p>
  
    <!-- Subtitle -->
    <p class="text-left text-justify text-base font-thin pt-2 pb-4 leading-relaxed">
      {{data.subtitle}}
    </p>
  
    <!-- Button -->
    <a 
      class="bg-white text-red-500 px-4 py-2 text-sm font-medium border-red-500 border-2 rounded-md hover:bg-red-500 hover:text-white transition-transform transform hover:scale-105 cursor-pointer"
      (click)="downloadAndNavigate()"
    >
      Scopri di più
    </a>
  </div>
  