import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  productStructure = [
    {
      id: 1,
      imageName: './assets/products/productOne.png',
      imageAlt: '',
      productName: 'Albero',
      productCompany: 'Poltrona Frau',
      productDescription: 'Immaginato alla fine degli anni ‘50 da Gianfranco Frattini come libreria da centro “terra-soffitto”, Albero nasce per un utilizzo in interni piuttosto che per la produzione di serie.'
    },
    {
      id: 2,
      imageName: './assets/products/productTwo.png',
      imageAlt: '',
      productName: 'Alisee',
      productCompany: 'Molteni',
      productDescription: 'Alisee conferma la razionalità espressiva di Matteo Nunziati.\n' +
        'Sistema completo e calibrato di complementi adatto a tutti gli ambienti della casa, sorprende per l’equilibrio estetico e per la rigorosa proporzione delle singole componenti.'
    },
    {
      id: 3,
      imageName: './assets/products/productThree.png',
      imageAlt: '',
      productName: 'D.153.1',
      productCompany: 'Molteni',
      productDescription: 'Disegnata nel 1953, la poltrona D.153.1 fa parte degli arredi dell’abitazione privata di Gio Ponti in via Dezza a Milano. La struttura è in ottone satinato, il rivestimento in pelle bicolore o in tessuto “Punteggiato” Rubelli.'
    },
    {
      id: 4,
      imageName: './assets/products/productFour.png',
      imageAlt: '',
      productName: 'D.154.2',
      productCompany: 'Molteni',
      productDescription: 'La poltrona, disegnata per uno dei progetti più cari a Gio Ponti, la villa dei collezionisti Planchart a Caracas del 1953-57, fa parte della Gio Ponti Collection, curata da Molteni&C con la direzione artistica dello Studio Cerri & Associati.'
    },
    {
      id: 5,
      imageName: './assets/products/productFive.png',
      imageAlt: '',
      productName: 'D.552.2',
      productCompany: 'Molteni',
      productDescription: 'Un’architettura, un virtuosismo di campate, un’acrobazia di proporzioni, un incrocio di incastri. Un tavolino infinito, un dialogo geometrico tra il vetro e il palissandro, un distillato di leggerezza e di astrazione. '
    },
    {
      id: 6,
      imageName: './assets/products/productSix.png',
      imageAlt: '',
      productName: 'D.655.1',
      productCompany: 'Molteni',
      productDescription: 'È caratterizzato dai frontali dei cassetti verniciati a mano in colore bianco con maniglie applicate di varie essenze (olmo, noce nazionale, mogano e palissandro). '
    },
    {
      id: 7,
      imageName: './assets/products/productSeven.png',
      imageAlt: '',
      productName: 'Eames Elephant ',
      productCompany: 'Vitra',
      productDescription: 'Eames Elephant prodotto da Vitra, è una icona di design, ma soprattutto un simbolo di simpatia. Un complemento di arredo per colorare la camera dei bambini e non solo, perfetto in ogni ambiente per portare un tocco di estro.'
    },
    {
      id: 8,
      imageName: './assets/products/productEight.png',
      imageAlt: '',
      productName: 'Eames Plastic Armchair DAW',
      productCompany: 'Vitra',
      productDescription: 'Eames Plastic Armchair Dawè una collezione di sedie disegnata da Charles & Ray Eames nel 1950 e prodotte da Vitra.Le forme organiche delle scocche offrono, con o senza imbottitura, un elevato comfort.'
    },
    {
      id: 9,
      imageName: './assets/products/productNine.png',
      imageAlt: '',
      productName: 'Eames Lounge Chair',
      productCompany: 'Vitra',
      productDescription: 'Eames Lounge Chair è una delle produzioni più conosciute di Charles e Ray Eames. La Lounge Chair è realizzata in legno con base in alluminio e rivestimento in pelle.'
    },
    {
      id: 10,
      imageName: './assets/products/productTen.png',
      imageAlt: '',
      productName: 'Eames Plastic Side Chair DSW',
      productCompany: 'Vitra',
      productDescription: 'Eames Plastic Side Chair DSW è tra le più famose sedie di Charles&Ray Eames per Vitra. La sedia Eames Plastic DSW è una vera leggenda dell\'industrial design, infatti è stata la prima sedia ad essere prodotta in serie.'
    },
    {
      id: 11,
      imageName: './assets/products/productEleven.png',
      imageAlt: '',
      productName: 'Louisa',
      productCompany: 'Molteni',
      productDescription: 'Vincent Van Duysen progetta Louisa, una serie di tavolini tondi e ovali in cui la presenza del legno e del cemento è dichiarata con una percezione tattile ed estetica innovativa.'
    },
    {
      id: 12,
      imageName: './assets/products/productTwelve.png',
      imageAlt: '',
      productName: 'Panton Chair',
      productCompany: 'Vitra',
      productDescription: 'Verner Panton per molti anni pensò ad una sedia in materiale sintetico prodotta in un pezzo unico. Negli anni 60 vennero alla luce i primi prototipi in collaborazione con Vitra ed a partire dal 1967 Panton Chair fu prodotta in serie.'
    },
    {
      id: 13,
      imageName: './assets/products/productThirteen.png',
      imageAlt: '',
      productName: 'Sanluca',
      productCompany: 'Poltrona Frau',
      productDescription: 'Nata dall’idea di Achille e Piergiacomo Castiglioni di svuotare l’imbottitura tradizionale per arrivare alle curvature “strettamente necessarie” al miglior sostegno, La poltrona Sanluca è uno straordinario esempio del design italiano in una delle sue stagioni più felici.'
    },
    {
      id: 14,
      imageName: './assets/products/productFourteen.png',
      imageAlt: '',
      productName: 'Vanity Fair ',
      productCompany: 'Poltrona Frau',
      productDescription: 'Ufficialmente denominata “modello 904” ma conosciuta, dal 1984, come Vanity Fair, questa seduta è divenuta nel tempo l’emblema stesso di Poltrona Frau. Il progetto, entrato in produzione nel 1930, pare sia stato rielaborato a partire dai disegni lasciati da Renzo Frau alla moglie Savina.'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
