<div class="flex justify-between flex-wrap">
  <div *ngFor="let data of productStructure" class="w-1/2 py-8 lg:w-full">
    <img
      src={{data.imageName}}
      class="margin-zero-auto outlet-image object-contain"
      alt={{data.imageAlt}}
    />

    <h1 class="product_structure_product_name_text">
      {{data.productName}}
    </h1>
    <h1 *ngIf="data.prezzoDiListino" class="product_structure_product_name_text">
      Prezzo di listino: <span class="bold_text">{{data.prezzoDiListino}}</span>
    </h1>
    <h1 class="product_structure_product_name_text">
      Prezzo: <span class="bold_text"> {{data.realPrice}} </span>
    </h1>
  </div>
</div>