<div class="pb-8">
  <app-header-view 
    [title]="'Il nostro showroom'">
  </app-header-view>

  <p class="description-text text-justify px-4 xl:px-0">
      Il nostro Showroom, ideato in collaborazione con Molteni & Co, si sviluppa su due piani ed accoglie calorosamente tutti coloro che entrano, offrendo un’ampia selezione di ambienti, dalle cucine alle aree living fino agli spazi notte. Il negozio è distribuito su un’importante superficie espositiva di 2000 mtq con relativo servizio di progettazione e realizzazione di arredi su misura sia per abitazioni che per locali commerciali, uffici e negozi. Il tutto combinato con armonia, come un puzzle ogni pezzo si integra al successivo dando origine a colori, texture e ambienti fluidi e piacevoli.
  </p>      

  <div id="carouselExampleIndicators" class="carousel slide relative lg:w-full w-3/4 margin-zero-auto pt-8" data-bs-ride="carousel">
    <!-- Carousel Indicators -->
    <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
      <button
        *ngFor="let image of showroomImages; let i = index"
        type="button"
        [class.active]="i === 0"
        data-bs-target="#carouselExampleIndicators"
        [attr.data-bs-slide-to]="i"
        [attr.aria-label]="'Slide ' + (i + 1)"
        [attr.aria-current]="i === 0 ? 'true' : null"
        class="rounded-full w-2 h-2 mx-1 bg-gray-500 active:bg-red-500"
      ></button>
    </div>

    <!-- Carousel Images -->
    <div class="carousel-inner relative w-full overflow-hidden">
      <div
        *ngFor="let image of showroomImages; let i = index"
        class="carousel-item float-left w-full"
        [class.active]="i === 0"
      >
        <img [src]="image.imageName" 
             class="block w-full h-auto object-cover" 
             [alt]="image.imageAlt" />
      </div>
    </div>

    <!-- Previous Button -->
    <button
      class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>

    <!-- Next Button -->
    <button
      class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
