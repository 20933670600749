import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../commonComponents/navigation-menu/navigationService';

@Component({
  selector: 'app-homepage-structure',
  templateUrl: './homepage-structure.component.html',
  styleUrls: ['./homepage-structure.component.css']
})
export class HomepageStructureComponent implements OnInit {

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
    this.navigationService.isNavigationMenuDarkMode = false;
  }
}