import { Component, OnInit } from '@angular/core';
import {NavigationService} from './navigationService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.css']
})
export class NavigationMenuComponent implements OnInit {

  isMobileMenuOpen = false;

  constructor(public navigationService: NavigationService,
              private router: Router) { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  setMobileMenuStatus() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    this.navigationService.isHamburgerMenuOpen = !this.navigationService.isHamburgerMenuOpen;
  }

  closeNavigationMenu(): void {
    this.isMobileMenuOpen = false;
    this.router.navigate(['']).then(r => {

    });
  }

  retrieveBorderColor(): string {
    if (this.isMobileMenuOpen) {
      return 'border-black-color';
    } else {
      if (this.navigationService.isNavigationMenuDarkMode) {
        return 'border-black-color';
      } else {
        return 'border-white';
      }
    }
  }

  retrieveTopColor(): string {
    if (this.isMobileMenuOpen) {
      return 'border-black-color white-background-color';
    } else {
      if (this.navigationService.isNavigationMenuDarkMode) {
        return 'border-black-color white-background-color';
      } else {
        return 'border-white custom-background-color';
      }
    }
  }
}
