<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="w-10/12 mx-auto">
    <header>
      <app-header-view [title]="'News'"></app-header-view>
    </header>

    <div class="flex flex-wrap">
      <div *ngFor="let newsItem of newsItems$ | async" class="w-1/2 py-8 lg:w-full">
        <app-news-content-component [data]="newsItem"></app-news-content-component>
      </div>
    </div>
  </div>

  <app-footer-component></app-footer-component>
</div>