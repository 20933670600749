<div class="w-10/12 margin-zero-auto pb-8">

  <div class="flex flex-row lg:flex-wrap">
    
    <div class="w-1/3 lg:w-full">
      <img
        src="./assets/iconaArredamentiLogo.png"
        class="w-40 pb-4 margin-zero-auto"
        alt=""
        routerLink="/"
      />
      <h1 class="text-gray-400 text-sm w-3/4 text-center margin-zero-auto"> © 2019 Icona Srl - PI: 02894570247 Privacy & Policy </h1>
    </div>

    <div class="w-1/3 lg:w-full flex flex-col text-center lg:py-8">
      <a routerLink="/chiSiamo" class="py-2">
        CHI SIAMO
      </a>
      <a routerLink="/lavori" class="py-2">
        LAVORI
      </a>
      <a routerLink="/news" class="py-2">
        NEWS
      </a>
      <a routerLink="/outlet" class="py-2">
        OUTLET
      </a>
      <a routerLink="/prodotti" class="py-2">
        PRODOTTI
      </a>
    </div>

    <div class="w-1/3 block lg:w-full">
        
      <a href="https://apps.apple.com/it/app/icona-ar/id1534445540?l=en">
        <img
          src="./assets/socialIcons/app.store.icon.svg"
          class="w-40 margin-zero-auto"
          alt=""
        />
      </a>

      <a href="https://www.instagram.com/iconarredamenti/?hl=en">
        <img
          src="./assets/socialIcons/instagramIcon.svg"
          alt=""
          class="margin-zero-auto py-4"
        />
      </a>
  
      <a href="https://it-it.facebook.com/Iconarredamenti/">
        <img
          src="./assets/socialIcons/facebookIcon.svg"
          alt=""
          class="margin-zero-auto"
        />
      </a>
    </div>
  </div>
</div>