import { Component, Input, OnInit } from '@angular/core';
import { NewsData } from '../../news-structure-component.component';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-news-content-component',
  templateUrl: './news-content-component.component.html',
  styleUrls: ['./news-content-component.component.css']
})
export class NewsContentComponentComponent implements OnInit {

  @Input() data: NewsData = {} as NewsData;

  coverImage: string;

  constructor(
    private router: Router,
    private storage: AngularFireStorage
  ) {
  }

  ngOnInit(): void {
    this.getImageUrlAux(this.data.coverImage).then(url => {
      this.coverImage = url
    })
  }

  async downloadAndNavigate() {
    try {
      const imagesUrl = await this.downloadImages();
      const queryParams = {
        data:  encodeURI(JSON.stringify(this.data.newsDetail)),
        urls: imagesUrl,
        middleImage: this.coverImage
      }
      this.navigateWithQueryParams(queryParams);
    } catch (error) {
      console.log(error);
    }
  }

  navigateWithQueryParams(queryParams: any) {
    this.router.navigate(['/news/vitra'], { queryParams: queryParams });
  }

  async downloadImages(): Promise<string[]> {
    const downloadPromises = this.data.newsDetail.images.map((imageUrl) => this.getImageUrlAux(imageUrl))
    return Promise.all(downloadPromises)
  }

  getImageUrlAux(url: string): Promise<string> {
    const ref = this.storage.ref(url)
    return ref.getDownloadURL().toPromise();
  }
}