<div>
  <div class="w-screen bg-gray-100 relative">
    <div class="container mx-auto flex flex-row lg:flex-col items-center justify-between w-4/5 gap-x-8 lg:gap-x-0">
      <!-- Text Section -->
      <div class="w-1/2 lg:w-full text-left">
        <h1 class="text-5xl lg:text-4xl font-light mb-4 lg:text-center lg:mt-8 font-medium leading-tight">
          Icona arredamenti: il piacere di arredare.
        </h1>
        <h2 class="text-lg lg:text-xl font-thin mb-6 leading-loose lg:text-center">
          In Icona crediamo che i pensieri e le sensazioni siano plasmati da ciò che ci circonda, a casa,
          al lavoro o in viaggio. Ecco perché ci sforziamo ogni giorno di migliorare gli interni attraverso
          il nostro lavoro.
        </h2>
        <div class="flex lg:justify-center">
          <button 
            class="bg-white px-6 py-2 border-2 border-red-500 rounded-md hover:bg-red-500 hover:text-white transition">
            Iscriviti alla nostra newsletter
          </button>
        </div>        
      </div>
      <!-- Image Section -->
      <div class="w-1/2 lg:w-full flex justify-center relative">
        <img 
          src="./assets/team_image.png" 
          class="w-full max-w-lg lg:max-w-full rounded-md -mb-8 mt-8" 
          alt="Team image" 
        />
      </div>
    </div>
  </div>
</div>