import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-mission-components',
  templateUrl: './our-mission-components.component.html',
  styleUrls: ['./our-mission-components.component.css']
})
export class OurMissionComponentsComponent implements OnInit {

  missionStructure = [
    {
      id: 1,
      missionNumber: '01',
      missionTitle: 'PROGETTAZIONE E VENDITA',
      missionDescription: 'Rilievo misure a domicilio e coordinamento dei lavori in cantiere. Consulenza su impiantistica, rifiniture architettoniche e arredo per abitazioni, uffici e attività commerciali.'
    },
    {
      id: 2,
      missionNumber: '02',
      missionTitle: 'TRASPORTO E MONTAGGIO',
      missionDescription: 'Montaggio effettuato da professionisti con esperienza pluriennale. Servizio preciso e meticoloso, in grado di affrontare qualsiasi tipo di montaggio.'
    },
    {
      id: 3,
      missionNumber: '03',
      missionTitle: 'ASSISTENZA POST-VENDITA',
      missionDescription: 'Garantiamo supporto post-vendita per il tuo investimento, offrendoti un servizio affidabile per mantenere i tuoi acquisti nel tempo.'
    },
    {
      id: 4,
      missionNumber: '04',
      missionTitle: 'FALEGNAMERIA TAYLOR MADE',
      missionDescription: 'Realizzazione di mobili su misura e lavori di falegnameria di alto livello, adattati alle esigenze specifiche e ai tuoi spazi.'
    },
  ];  

  constructor() { }

  ngOnInit(): void { }

}
