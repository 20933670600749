<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="news_component_main_structure">
    <div class="news_component_title_container">
      <h1 class="news_title_news">
        News
      </h1>
    </div>
    <hr class="solid">

    <div class="flex flex-wrap">
      <div *ngFor="let newsItem of newsItems$ | async" class="w-1/2 py-8 lg:w-full">
        <app-news-content-component [data]="newsItem"></app-news-content-component>
      </div>
    </div>
  </div>

  <app-footer-component></app-footer-component>
</div>