import { Component, OnInit } from '@angular/core';
import 'tw-elements';

@Component({
  selector: 'app-about-us-second-section',
  templateUrl: './about-us-second-section.component.html',
  styleUrls: ['./about-us-second-section.component.css']
})
export class AboutUsSecondSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showroomImages = [
    {
      id: 1,
      imageName: './assets/about-us-showroom/showroom1.png',
      imageAlt: '',
    },
    {
      id: 2,
      imageName: './assets/about-us-showroom/showroom2.png',
      imageAlt: '',
    },
    {
      id: 3,
      imageName: './assets/about-us-showroom/showroom3.png',
      imageAlt: '',
    },
    {
      id: 4,
      imageName: './assets/about-us-showroom/showroom4.png',
      imageAlt: '',
    },
    {
      id: 5,
      imageName: './assets/about-us-showroom/showroom5.png',
      imageAlt: '',
    },
    {
      id: 6,
      imageName: './assets/about-us-showroom/showroom6.png',
      imageAlt: '',
    },
    {
      id: 7,
      imageName: './assets/about-us-showroom/showroom7.png',
      imageAlt: '',
    },
    {
      id: 8,
      imageName: './assets/about-us-showroom/showroom8.png',
      imageAlt: '',
    },
    {
      id: 9,
      imageName: './assets/about-us-showroom/showroom9.png',
      imageAlt: '',
    },
    {
      id: 10,
      imageName: './assets/about-us-showroom/showroom10.png',
      imageAlt: '',
    },
  ]
}
