import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unique-project-components',
  templateUrl: './unique-project-components.component.html',
  styleUrls: ['./unique-project-components.component.css']
})
export class UniqueProjectComponentsComponent implements OnInit {

  firstDescription = 'Per noi, la qualità è sempre al primo posto.\n' +
    'Per questo collaboriamo con i migliori brand presenti nel mercato.'

  secondDescription = 'Il nostro team di arredatori e montatori garantisce una scrupolosa attenzione ai dettagli, dovuta a una lunga esperienza sui materiali, pelli, tessuti, marmi e legni, unita ad una continua ricerca ed innovazione.';

  thirdDescription = 'Il nostro Showroom, ideato in collaborazione con Molteni & Co, si sviluppa su due piani ed accoglie calorosamente tutti coloro che entrano offrendo un’ampia selezione di ambienti, dalle nostre cucine alle aree living fino agli spazi notte .';

  constructor() { }

  ngOnInit(): void {
  }

}
