<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="w-10/12 margin-zero-auto">

    <header>
      <app-header-view [title]="'Outlet'"></app-header-view>
    </header>

    <app-outlet-product-list></app-outlet-product-list>
  </div>

  <app-footer-component></app-footer-component>
</div>
