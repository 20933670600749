<div class="w-4/5 lg:w-full">
    <img [src]="coverImage" class="" [alt]="data.altText" />
    <p class="text-left font-semibold pt-2">
        {{data.title}}
    </p>
    <p class="text-left text-xs font-thin">
        {{data.date}}
    </p>
    <p class="text-left description-text text-justify pb-4">
        {{data.subtitle}}
    </p>
    <a class="bg-white p-2 button-text-style border-red-500 border-2 rounded-md hover:bg-red-500 hover:text-white cursor-pointer"
        (click)="downloadAndNavigate()">
        Scopri di più
    </a>
</div>