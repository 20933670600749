<div class="pt-8">
    <div class="bg-black w-full flex flex-col items-center pb-16">
        <!-- Header Component -->
        <div class="w-10/12 mx-auto">
            <app-header-view
                [title]="'Cosa dicono i nostri clienti'"
                [description]="'Ci impegniamo costantemente per offrire il meglio ai nostri clienti, realizzando tutti i loro desideri e creando spazi confortevoli, funzionali ed esteticamente impeccabili in cui vivere.'"
                [textColor]="'text-white'"
            ></app-header-view>
        </div>

        <!-- Outer Black Section -->
        <div class="max-w-4xl flex flex-col items-center">
            <!-- Fixed Height Section -->
            <div class="w-full rounded-md flex flex-col justify-between items-center min-h-[400px]"> <!-- Adjust min-h -->
                <!-- Quote Section -->
                <div class="flex items-center justify-center text-center">
                    <p class="text-6xl lg:text-4xl text-white leading-relaxed font-light">
                        <span class="text-8xl lg:text-6xl text-gray-400 leading-none -translate-y-3">“</span>
                        {{ currentQuote }}
                        <span class="text-8xl lg:text-6xl text-gray-400 leading-none translate-y-3">”</span>
                    </p>
                </div>

                <!-- Arrows -->
                <div class="mt-16 flex space-x-8">
                    <!-- Previous Arrow -->
                    <button (click)="prevQuote()" aria-label="Previous Quote" class="text-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="white" stroke-width="3" viewBox="0 0 240 80" width="120" height="80" class="cursor-pointer">
                            <path d="M80 20 L40 40 L80 60" />
                            <line x1="40" y1="40" x2="200" y2="40" />
                        </svg>
                    </button>

                    <!-- Next Arrow -->
                    <button (click)="nextQuote()" aria-label="Next Quote" class="text-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="white" stroke-width="3" viewBox="0 0 240 80" width="120" height="80" class="cursor-pointer">
                            <path d="M160 20 L200 40 L160 60" />
                            <line x1="40" y1="40" x2="200" y2="40" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
