<div class="products_structure_flex_box_container">
  <div *ngFor="let data of productStructure" class="products_structure_product_element">
    <img
      src={{data.imageName}}
      class="products_structure_product_image"
      alt={{data.imageAlt}}
    />

    <h1 class="product_structure_product_name_text">
      {{data.productName}}
    </h1>
    <h1 class="product_structure_product_name_text">
      {{data.productCompany}}
    </h1>
    <h1 class="product_structure_product_description_text">
      {{data.productDescription}}
    </h1>
  </div>
</div>
