import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../commonComponents/navigation-menu/navigationService';

@Component({
  selector: 'app-image-and-companies-component',
  templateUrl: './image-and-companies-component.component.html',
  styleUrls: ['./image-and-companies-component.component.css']
})
export class ImageAndCompaniesComponentComponent implements OnInit {

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  openNewsletter() {
    window.open('https://tinyletter.com/IconaArredamenti', 'popupwindow', 'scrollbars=yes,width=800,height=600'); return true
  }
}