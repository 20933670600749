import { Component, OnInit } from '@angular/core';
import {WorksFilterEnum} from './data/works-filter-enum';

@Component({
  selector: 'app-work-design-image-component',
  templateUrl: './work-design-image-component.component.html',
  styleUrls: ['./work-design-image-component.component.css']
})
export class WorkDesignImageComponentComponent implements OnInit {

  currentSelectedFilter: WorksFilterEnum = WorksFilterEnum.Bagni;

  public get filterSelectedEnum(): typeof WorksFilterEnum {
    return WorksFilterEnum;
  }

  worksFilter: WorksFilterEnum[] = [
    WorksFilterEnum.Bagni,
    WorksFilterEnum.Cucina,
    WorksFilterEnum.Esterni,
    WorksFilterEnum.Illuminazione,
    WorksFilterEnum.Living,
    WorksFilterEnum. Workspace,
    WorksFilterEnum.CameraDaLetto,
  ];

  bagnoStructure = [
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Edonè',
      imageAlt: '',
      workImage: './assets/works/bagno/edone1.png',
      isLeft: false
    },
    {
      id: 2,
      workNumber: '02',
      workTitle: 'Edonè',
      imageAlt: '',
      workImage: './assets/works/bagno/edone2.png',
      isLeft: true
    },
    {
      id: 4,
      workNumber: '04',
      workTitle: 'Edonè',
      imageAlt: '',
      workImage: './assets/works/bagno/edone4.png',
      isLeft: true
    },
    {
      id: 4,
      workNumber: '04',
      workTitle: 'Edonè',
      imageAlt: '',
      workImage: './assets/works/bagno/edone6.png',
      isLeft: true
    },
    {
      id: 4,
      workNumber: '04',
      workTitle: 'Edonè',
      imageAlt: '',
      workImage: './assets/works/bagno/edone7.png',
      isLeft: true
    },
    {
      id: 5,
      workNumber: '04',
      workTitle: 'Edonè',
      imageAlt: '',
      workImage: './assets/works/bagno/edone9.png',
      isLeft: true
    },
    {
      id: 5,
      workNumber: '04',
      workTitle: 'Edonè',
      imageAlt: '',
      workImage: './assets/works/bagno/edonè.png',
      isLeft: true
    },
  ];

  cameraDaLettoStructure = [
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Flou',
      imageAlt: '',
      workImage: './assets/works/cameraDaLetto/flou.png',
      isLeft: false
    },
    {
      id: 2,
      workNumber: '02',
      workTitle: 'Flou',
      imageAlt: '',
      workImage: './assets/works/cameraDaLetto/flou1.png',
      isLeft: true
    },
    {
      id: 4,
      workNumber: '04',
      workTitle: 'Flou',
      imageAlt: '',
      workImage: './assets/works/cameraDaLetto/Flou2.png',
      isLeft: true
    },
    {
      id: 4,
      workNumber: '04',
      workTitle: 'Flou',
      imageAlt: '',
      workImage: './assets/works/cameraDaLetto/flou3.png',
      isLeft: true
    },
    {
      id: 4,
      workNumber: '04',
      workTitle: 'Flou',
      imageAlt: '',
      workImage: './assets/works/cameraDaLetto/flou4.png',
      isLeft: true
    },
    {
      id: 4,
      workNumber: '04',
      workTitle: 'Flou',
      imageAlt: '',
      workImage: './assets/works/cameraDaLetto/flou5.png',
      isLeft: true
    },
    {
      id: 4,
      workNumber: '04',
      workTitle: 'Flou',
      imageAlt: '',
      workImage: './assets/works/cameraDaLetto/flou6.png',
      isLeft: true
    },
  ];

  cucineStructure = [
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/Hi line 2.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/Ratio_1.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/Hi line 3.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/Ratio9.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/Ratio_2.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/Ratio_4.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/Ratio_5.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/XY_1.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/XY_2.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Dada',
      imageAlt: '',
      workImage: './assets/works/cucine/XY_3.png',
      isLeft: false
    },
  ];

  esterniStructure = [
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Emu',
      imageAlt: '',
      workImage: './assets/works/esterni/emu.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Emu',
      imageAlt: '',
      workImage: './assets/works/esterni/emu1.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Emu',
      imageAlt: '',
      workImage: './assets/works/esterni/emu2.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Emu',
      imageAlt: '',
      workImage: './assets/works/esterni/Emu3.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Tribu',
      imageAlt: '',
      workImage: './assets/works/esterni/tribu.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Tribu',
      imageAlt: '',
      workImage: './assets/works/esterni/tribu 5.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Tribu',
      imageAlt: '',
      workImage: './assets/works/esterni/tribu 7.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Varaschin',
      imageAlt: '',
      workImage: './assets/works/esterni/varaschin.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Varaschin',
      imageAlt: '',
      workImage: './assets/works/esterni/varaschin1.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Varaschin',
      imageAlt: '',
      workImage: './assets/works/esterni/varaschin2.png',
      isLeft: false
    },
  ];

  illuminazioneStructure = [
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Cattelani & Smith',
      imageAlt: '',
      workImage: './assets/works/illuminazione/cattelani.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Cattelani & Smith',
      imageAlt: '',
      workImage: './assets/works/illuminazione/cattelani1.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Cattelani & Smith',
      imageAlt: '',
      workImage: './assets/works/illuminazione/cattelani2.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Cattelani & Smith',
      imageAlt: '',
      workImage: './assets/works/illuminazione/cattelani6.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Louis Polsen',
      imageAlt: '',
      workImage: './assets/works/illuminazione/louis polsen.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Louis Polsen',
      imageAlt: '',
      workImage: './assets/works/illuminazione/louis polsen3.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Louis Polsen',
      imageAlt: '',
      workImage: './assets/works/illuminazione/louis polsen-1.png',
      isLeft: false
    },
  ];

  livingStructure = [
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Poltrna Frau',
      imageAlt: '',
      workImage: './assets/works/living/cometogether-img_0825.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Ditre',
      imageAlt: '',
      workImage: './assets/works/living/Ditre.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Ditre',
      imageAlt: '',
      workImage: './assets/works/living/Ditre1.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Molteni',
      imageAlt: '',
      workImage: './assets/works/living/molteni6.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Molteni',
      imageAlt: '',
      workImage: './assets/works/living/Molteni_1.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Vitra',
      imageAlt: '',
      workImage: './assets/works/living/vitra.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Vitra',
      imageAlt: '',
      workImage: './assets/works/living/vitra3.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Poltrona Frau',
      imageAlt: '',
      workImage: './assets/works/living/FR-572_COMP1-grantorino__ambientate___65i4586.png',
      isLeft: false
    },
  ];

  workspaceStructure = [
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Vitra',
      imageAlt: '',
      workImage: './assets/works/workspace/vitra.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Vitra',
      imageAlt: '',
      workImage: './assets/works/workspace/vitra2.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Vitra',
      imageAlt: '',
      workImage: './assets/works/workspace/vitra3.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Vitra',
      imageAlt: '',
      workImage: './assets/works/workspace/vitra4.png',
      isLeft: false
    },
    {
      id: 1,
      workNumber: '01',
      workTitle: 'Vitra',
      imageAlt: '',
      workImage: './assets/works/workspace/vitra6.png',
      isLeft: false
    },

  ];

  constructor() {

  }

  ngOnInit(): void {
  }

  changeCurrentFilterSelection(filterSelected: WorksFilterEnum): void {
    this.currentSelectedFilter = filterSelected;
  }

  getCurrentArray(): any {
    switch (this.currentSelectedFilter) {
      case WorksFilterEnum.Bagni:
        return this.bagnoStructure;
      case WorksFilterEnum.CameraDaLetto:
        return this.cameraDaLettoStructure;
      case WorksFilterEnum.Cucina:
        return this.cucineStructure;
      case WorksFilterEnum.Esterni:
        return this.esterniStructure;
      case WorksFilterEnum.Illuminazione:
        return this.illuminazioneStructure;
      case WorksFilterEnum.Living:
        return this.livingStructure;
      case WorksFilterEnum.Workspace:
        return this.workspaceStructure;
      default:
        return [];
    }
  }
}
