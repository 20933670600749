import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../commonComponents/navigation-menu/navigationService';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, forkJoin } from 'rxjs';
import 'firebase/compat/database'
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-news-structure-component',
  templateUrl: './news-structure-component.component.html',
  styleUrls: ['./news-structure-component.component.css']
})
export class NewsStructureComponentComponent {

  newsItems$: Observable<NewsData[]>;

  constructor(
    public navigationService: NavigationService,
    private db: AngularFireDatabase
  ) {
    this.newsItems$ = this.db.list('data').valueChanges().pipe(
      map((data: any[]) => {
        return data.map(item => {
          const newsData = new NewsData();
          newsData.title = item.title;
          newsData.subtitle = item.subtitle;
          newsData.coverImage = item.coverImage;
          newsData.date = item.date
          newsData.altText = item.altText
          newsData.newsDetail = item.newsDetail
          return newsData;
        });
      })
    );
  }

  ngOnInit(): void {
    this.navigationService.isNavigationMenuDarkMode = true;
  }
}

export interface NewsDetail {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  customView: string;
  images: string[];
}

export class NewsData {
  coverImage: string = '';
  altText: string;
  title: string;
  date: string;
  subtitle: string;
  newsDetail: NewsDetail;
}