export enum WorksFilterEnum {
  Bagni = 'Bagno',
  CameraDaLetto = 'Zona notte',
  Cucina = 'Cucina',
  Esterni = 'Esterno',
  Illuminazione = 'Illuminazione',
  Living = 'Living',
  Workspace = 'Workspace',
}

