<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="w-10/12 mx-auto">

    <header>
      <app-header-view [title]="'I nostri prodotti'"></app-header-view>
    </header>

    <app-product-list></app-product-list>
  </div>

  <app-footer-component></app-footer-component>

</div>
