<div class="py-24 container mx-auto flex lg:flex-col flex-row items-start gap-8 lg:gap-0">
    <!-- Dynamic Title -->
    <h1
      class="w-2/3 lg:w-full lg:text-4xl text-6xl font-medium text-left lg:text-center leading-tight"
      [ngClass]="textColor"
    >
      {{ title }}
    </h1>
  
    <div
      *ngIf="description"
      class="w-1/3 lg:w-full text-sm text-base font-light text-left pt-4 lg:text-center leading-loose"
      [ngClass]="textColor"
    >
      <!-- Dynamic Description -->
      <p>
        {{ description }}
      </p>
    </div>
  </div>
  