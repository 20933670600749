<div [ngClass]="{hide_content_for_hamburger_menu: navigationService.isHamburgerMenuOpen}">
  <div class="our_products_component_main_structure">

    <div class="our_products_component_title_container">
      <h1 class="our_products_main_title_our_products">
        I nostri prodotti
      </h1>
    </div>

    <hr class="solid">

    <app-product-list></app-product-list>
  </div>

  <app-footer-component></app-footer-component>

</div>
