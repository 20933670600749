<div>
  <div class="work_filter_container">
    <div *ngFor="let data of worksFilter">
      <button class="works_filter_button_text" (click)="changeCurrentFilterSelection(data)">
        <h1 class="works_filter_text" [ngClass]="{works_filter_text_selected: currentSelectedFilter == data}">
          {{data}}
        </h1>
      </button>
    </div>
  </div>

  <hr class="solid"/>

  <div class="work_design_element_container">
    <div *ngFor="let data of getCurrentArray()" class="work_design_element_row">
      <img
        src={{data.workImage}}
        class="work_design_image"
        alt=""
      />
      <h1 class="work-design_work_title_text"> {{data.workTitle}} </h1>
    </div>
  </div>
</div>
