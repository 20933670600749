<div class="pb-8">
  <div class="py-16 bg-gray-100">
    <!-- Section Title -->
    <h1 class="text-center font-light text-4xl md:text-5xl mb-12">
      Contattaci
    </h1>
  
    <!-- Contact Options -->
    <div class="grid md:grid-cols-1 lg:grid-cols-2 grid-cols-4 gap-8 container mx-auto w-11/12">
      <!-- Phone -->
      <div class="flex flex-col items-center text-center">
        <h1 class="text-sm font-medium mb-2">TELEFONO</h1>
        <a href="tel:0444560138" class="text-lg font-light text-black">
          0444.560138
        </a>
      </div>
  
      <!-- Address -->
      <div class="flex flex-col items-center text-center">
        <h1 class="text-sm font-medium mb-2">INDIRIZZO</h1>
        <a href="https://www.google.com/maps/search/?api=1&query=45.54508489047022,11.523196640514943" class="text-lg font-light text-black">
          Viale Mercato Nuovo, 38
        </a>
      </div>
  
      <!-- Email -->
      <div class="flex flex-col items-center text-center">
        <h1 class="text-sm font-medium mb-2">EMAIL</h1>
        <h2 class="text-lg font-light underline cursor-pointer" (click)="onEmailClick()">
          info@iconarredamenti.it
        </h2>
      </div>
  
      <!-- Hours -->
      <div class="flex flex-col items-center text-center">
        <h1 class="text-sm font-medium mb-2">ORARI</h1>
        <p class="text-base font-light">Mar-Sab:</p>
        <p class="text-base font-light">9.00-12:30 / 15:00-19:30</p>
        <p class="text-base font-light mt-2">Lun:</p>
        <p class="text-base font-light">15.00-19:30</p>
      </div>
    </div>
  </div>
  
</div>