<div>
  <div class="homepage_main_large_image">
    <div class="homepage_first_section_container">
      <div class="homepage_first_section_text">
        <h1>
          Icona arredamenti: il piacere di arredare.
        </h1>
        <h2>
          In Icona crediamo che i pensieri e le sensazioni siano plasmati da ciò che ci circonda, a casa,
          al lavoro o in viaggio. Ecco perché ci sforziamo ogni giorno di migliorare gli interni attraverso
          il nostro lavoro.
        </h2>
        <div class="pt-4">
          <button (click)="openNewsletter()"
            class="bg-white p-4 border-red-500 border-2 rounded-md hover:bg-red-500 hover:text-white">
            Iscriviti alla nostra newsletter
          </button>
          <i class="fa fa-area-chart"></i>
        </div>
      </div>
      <div>
        <img src="./assets/_DSC5733%201.png" class="team_homepage_image" alt="Team image" />
      </div>
    </div>
  </div>

  <div class="homepage_first_section_geometry_container">

    <h1 class="with_best_brands_title">
      Insieme ai migliori brand
    </h1>

    <div class="flex items-start justify-between flex-wrap">
      <div *ngFor="let data of companiesStructure" class="w-15/100 pb-16 lg:w-1/2">
        <img src={{data.imageName}} class="w-11/12 lg:w-1/2 margin-zero-auto" alt={{data.imageAlt}} />
      </div>
    </div>

    <div class="w-full flex justify-center pb-8">
      <button routerLink="/brands" (click)="setNavigationServiceProps(true)"
        class="bg-white p-4 border-red-500 border-2 rounded-md hover:bg-red-500 hover:text-white">
        <h1> SCOPRILI TUTTI </h1>
      </button>
    </div>

    <hr class="solid">
  </div>

</div>