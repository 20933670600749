import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {NavigationService} from './commonComponents/navigation-menu/navigationService';
import { getStorage, ref } from "firebase/storage";

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NavigationService]
})
export class AppComponent {
  title = 'icona-arredamenti-website';

  constructor(
    private navigationService: NavigationService, 
    public router: Router
    ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview')
      }
    })
  }
}
