import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-people-say',
  templateUrl: './what-people-say.component.html',
  styleUrls: ['./what-people-say.component.css']
})
export class WhatPeopleSayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  quotes: string[] = [
    "Collaborare con loro è stato un vero piacere: competenza e creatività di altissimo livello.",
    "Il risultato finale ha superato ogni aspettativa, un lavoro impeccabile e curato nei minimi dettagli.",
    "L’attenzione ai particolari e la qualità dei materiali hanno reso unico ogni ambiente.",
    "Un servizio eccellente che combina professionalità e passione per il design.",
    "Ogni fase del progetto è stata gestita con precisione e dedizione, offrendo risultati straordinari."
  ];
  
  currentIndex: number = 0;

  get currentQuote(): string {
    return this.quotes[this.currentIndex];
  }

  nextQuote(): void {
    this.currentIndex = (this.currentIndex + 1) % this.quotes.length;
  }

  prevQuote(): void {
    this.currentIndex = 
      (this.currentIndex - 1 + this.quotes.length) % this.quotes.length;
  }
}
